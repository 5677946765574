import React from "react"
import bandWave from "../../assets/images/bg/banner4-wave1.svg"
import bandWave2 from "../../assets/images/bg/banner4-wave2.svg"
import head4Spring from "../../assets/images/bg/banner4-spring.svg"
import banDotSquare from "../../assets/images/bg/banner4-dot-square.svg"
import ban from "../../../assets/illustrations/Logo.png"
import { Link } from "react-scroll"
const content = () => {
  return (
    <>
      <div className="banner-section4">
        <img src={bandWave} className="banner4-wave1 img-fluid" alt="Wave" />
        <img src={bandWave2} className="banner4-wave2 img-fluid" alt="Wave 1" />
        <img
          src={head4Spring}
          className="banner4-spring img-fluid"
          alt="spring svg"
        />
        <img
          src={banDotSquare}
          className="banner4-dot-sq img-fluid"
          alt="dots"
        />
        <a href="#about" className="go-down-btn"></a>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <div
                className="banner-content style-2 wow fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <span>Welcome to ‘the Cottage’</span>
                <h1>Home to a new world of books</h1>
                <p className="para">
                  SPA Cottage Publishing is a world of Books: Fiction,
                  Non-Fiction, Memoir Writing, Local Memoir/History, Classics,
                  Irish Language Series, Gothic, Short Stories, and other
                  genres.
                </p>
                <div className="button-group gap-5">
                  <Link
                    to="about"
                    smooth={true}
                    offset={-90}
                    duration={100}
                    className="eg-btn btn--primary4 btn--lg"
                    style={{ cursor: "pointer" }}
                  >
                    Tell Me More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 position-relative">
              <div className="banner4-img">
                <img src={ban} alt="Spa Cottage publishing logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sponsor-section4">
        <div className="container">
          <div className="sponsor-wrapper">
            <h2 style={{ textAlign: "center", margin: "auto" }}>
              ‘If we surrendered to earth’s intelligence we could rise up rooted
              like trees.’ —Rainer Maria Rilke
            </h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default content
