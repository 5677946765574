import React from "react"
import { Layout } from "../components/common"
import { About, Featured } from "../components/landing"
import Banner from "../components/theme/Header/content"
import "bootstrap/dist/css/bootstrap.min.css"
import { Helmet } from "react-helmet"
const Home = () => (
  <Layout>
    <Helmet>
      <title>Home</title>
    </Helmet>
    <Banner />
    <About />
    <Featured />
  </Layout>
)

export default Home
