import React from "react"
import featuredBook from "../../../assets/illustrations/TheIrishPercyFrench.jpeg"
import Carousel from "react-bootstrap/Carousel"
import waterMark from "../../assets/images/bg/watermark2-bg.png"
export const Featured = () => {
  return (
    <div
      className="about-section3 position-relative overflow-hidden pt-120"
      id="featuredBook"
      style={{ paddingTop: "0px" }}
    >
      <img src={waterMark} alt="background" className="watermark2-bg" />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div
              className="section-title3 primary4 text-cener"
              style={{ marginBottom: "12px" }}
            >
              <span>-Featured Book-</span>
              <h3>The Irish Percy French</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-center g-4">
          <div
            className="col-xl-7 col-lg-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={featuredBook}
              className="img-fluid about4-image"
              alt="featured book"
            />
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about3-content style-2">
              <Carousel
                variant="dark"
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
                slide={false}
                className="align-items-center"
                controls={false}
              >
                <Carousel.Item>
                  <p className="para">
                    ‘He would give the most versatile single entertainment I
                    have ever seen.’—L. A. G. Strong
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="para">
                    ‘He could pick up the banjo and strum the symphony leading
                    into one of his incomparable ditties.’—Valentine Vousden
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="para">
                    ‘French was a story-teller, a talker, an actor and an
                    artist.’—Alfred Perceval Graves
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="para">
                    ‘The general public knew him as an Irish entertainer, whose
                    entertainments were purely human in their fantasy, with
                    sensitive touches of pathos; entertainments from which one
                    came away happy and uplifted. He loved the innocent
                    love-making, the innocent domesticities. He was incapable of
                    a double entendre or any griminess’.—Katharine Tynan
                  </p>
                </Carousel.Item>
              </Carousel>
              <div
                className="about-footer d-flex jusify-content-start align-items-center flex-wrap gap-4"
                style={{ justifyContent: "center" }}
              >
                <a
                  href="https://www.amazon.co.uk/Irish-Percy-French-ebook/dp/B0BK1LPCW7/ref=sr_1_5?crid=15PBPM99RYXCQ&keywords=pamela+mary+brown&qid=1668286408&sprefix=pamela+mary+brown%2Caps%2C133&sr=8-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="eg-btn btn--primary4 btn--lg"
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
