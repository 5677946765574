import React from "react"
import waterMark from "../../assets/images/bg/watermark2-bg.png"

export const About = () => {
  return (
    <>
      <div
        className="service-section4 pt-120 pb-120 position-relative overflow-hidden"
        style={{ paddingTop: "25px", paddingBottom: "25px" }}
        id="about"
      >
        <img src={waterMark} className="watermark1-bg" alt="watermark background" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-What We Offer-</span>
                <h3>Our Services</h3>
                <p>
                  Let us give you the quick tour. You can then settle in and
                  browse at your own pace.
                </p>
              </div>
            </div>
          </div>
          <div className="content">
            <p className="para">
              SPA Cottage Publishing is a world of Books: Fiction, Non-Fiction,
              Memoir Writing, Local Memoir/History, Classics, Irish Language
              Series, Gothic, Short Stories, and other genres.
            </p>
            <p className="para">
              SPA Cottage Publishing identifies with the revolution of online
              publishing platforms while accepting the traditional formats that
              came with the printing press and onwards. SPA Cottage Publishing
              books are available in digital, Ebook, Kindle, paperback, hardcopy
              and crucially Gaelic language and culture.
            </p>
            <p className="para">
              Our mission is traditional, ground-breaking, bringing together our
              experienced and professional team, collective and individual.
              While we bifurcate the new frontiers and the existing publishing
              formats, we see ourselves as leading and innovative within these.
            </p>
            <p className="para">
              Global understanding of the presentation of text in all
              techno-formats is always underscored by the realisation that
              language in any format is about communication and remains a
              collection of words, linguistic signs and symbols. The big
              challenge is of course that global communication has reached its
              apogee and with the potential for text to reach every member of
              the planet’s population.
            </p>
            <p className="para">
              <b>SPA Cottage Publishing </b>
              <i>
                {" "}
                takes its name from the 18th Century sulphur well on the McManus
                Farmstead facing Sliabh an Iarain (Iron Mountain); situated on
                the Spa River in County Leitrim. The farm is currently cared for
                by Farming for Nature Ambassador Michael McManus, innovator in
                farm diversification and organic farmer. Founder and Director of
                Spa Cottage organic skincare, landscape painter and former
                Leitrim area correspondent, columnist, journalist, and political
                analyst.
              </i>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
